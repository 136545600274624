/*
 * @Author: liyu
 * @Date: 2022-04-14 18:01:43
 * @LastEditTime: 2022-04-22 11:16:02
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/SectionCamp/index.tsx
 */
import React from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import classnames from 'classnames'
import * as styles from './index.module.less'

const SectionCamp = ({ className }) => {
  return (
    <section className={classnames(styles.solutionConsultant, className)}>
      <div className="camp-container">
        <div className="camp-title">提升数据意识，打造精品游戏</div>
        <div className="camp-type">神策阿尔法训练营</div>
        <div className="camp-dsc">
          全周期性、训战结合式督导、数据意识赋能服务，综合性培养团队的数据认知、分析与应用能力，实现研运能力跃迁。
        </div>
        <div className="camp-img">
          <img
            className="hidden lg:block"
            src={require('assets/images/solution/game/camp-flow-pc.png').default}
            alt=""
          />
          <img
            className="block lg:hidden"
            src={require('assets/images/solution/game/camp-flow-mb.png').default}
            alt=""
          />
        </div>
        <div className="camp-btn">
          <ScanCodeConsultButton
            buttonText="扫码咨询方案顾问"
            qrcode={require(`assets/images/solution/game/panorama_code.png`).default}
            alt="扫码咨询方案顾问"
          />
        </div>
      </div>
    </section>
  )
}

export default SectionCamp
