import * as React from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import GameBanner from 'components/GameBanner'
import SectionProgram from 'components/Game/SectionProgram'
import SectionResources from 'components/Game/SectionResources'
import SectionCamp from 'components/Game/SectionCamp'
import SectionCounsel from 'components/Game/SectionCounsel'
import SectionReservation from 'components/Game/SectionReservation'
import { isBrowser, isPc } from 'utils/utils'
import * as styles from './index.module.less'
import gameLogePC from 'assets/images/solution/game/icon-banner.png'
import gameLogeMb from 'assets/images/solution/game/icon-banner-mb.png'
const { useState, useEffect } = React

const Game: React.FC = (props) => {
  return (
    <Layout {...props}>
      <Seo
        title="游戏运营解决方案 | 游戏用户智能运营平台 - 神策数据解决方案"
        description="神策数据基于游戏数据埋点和数据采集，分析游戏用户行为，从而构建游戏玩家画像，帮助企业实时分析渠道投放数据，调整广告投放策略，为企业提供科学的游戏数据分析思路和更具营销能力的游戏用户运营方案，助力企业实现游戏精品化研运，打造爆款游戏。"
        keywords="游戏数据分析，游戏用户行为分析，游戏运营，游戏用户运营，游戏玩家分析"
        saTitle="首页-产品（行业解决方案）-游戏"
      />
      <GameState />
      {/* <SFPopup pageId="solution_game" /> */}
    </Layout>
  )
}

export const GameState: React.FC = () => {
  const [gameLoge, setGameLoge] = useState('')
  const [borderRadius, setBorderRadius] = useState('20px')
  const [isFixed, setFixed] = useState('')
  // 屏幕大小变化
  const resizeBind = () => {
    if (isPc()) {
      setGameLoge(gameLogePC)
      setBorderRadius('20px')
    } else {
      setGameLoge(gameLogeMb)
      setBorderRadius('2rem')
    }
  }
  // 滚动
  const bindHandleScroll = (event) => {
    if (isBrowser()) {
      const scrollTop =
        (event.srcElement ? event.srcElement.documentElement.scrollTop : false) ||
        window.pageYOffset ||
        (event.srcElement ? event.srcElement.body.scrollTop : 0)

      if (isPc()) {
        if (scrollTop > 670 && scrollTop < 2300) {
          setFixed('fixed')
        } else if (scrollTop < 670) {
          setFixed('')
        } else if (scrollTop > 2300) {
          setFixed('')
        }
      }
    }
  }
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', resizeBind)
      window.addEventListener('scroll', bindHandleScroll)
    }
    if (isPc()) {
      setGameLoge(gameLogePC)
      setBorderRadius('22px')
    } else {
      setGameLoge(gameLogeMb)
      setBorderRadius('22px')
    }

    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', resizeBind)
        window.removeEventListener('scroll', bindHandleScroll)
      }
    }
  }, [])

  return (
    <main className={styles.Game}>
      {/* section1 */}
      <GameBanner
        descText="不只是游戏数据分析，还有玩家画像洞察、买量推广优化、玩家运营触达，助力企业抢滩游戏精品化大时代！"
        borderRadius={borderRadius}
        gameLoge={gameLoge}
        className={styles.bannerWrap}
      />
      {/*section2  */}
      <SectionProgram key={'game-marketing-cycle'} className={styles.marketingCycle} isFixed={isFixed} />
      {/* section3 */}
      <SectionResources key={'game-package-information'} className={styles.packageInformation} />
      {/* section4 */}
      <SectionCamp key={'game-solution-consultant'} className={'solution-consultant'} />
      {/* section5 */}
      <SectionCounsel key={'game-other-consulting'} className={'other-consulting'} />
      {/* section6 */}
      <SectionReservation key={'game-reservation-consultation'} className={'reservation-consultation'} />
    </main>
  )
}

export default Game
