/*
 * @Author: liyu
 * @Date: 2022-04-11 18:21:20
 * @LastEditTime: 2022-04-20 17:56:57
 * @Description: file content
 * @FilePath: /sensors-www/src/components/GameBanner/index.tsx
 */
import React from "react";
import classnames from "classnames";
import * as styles from "./index.module.less";
import Button from "components/Base/Button";

interface GameBannerProps {

  descText?: string;
  gameLoge?: string;
  borderRadius?: string;
  className?: string;
}
const GameBanner: React.FC<GameBannerProps> = ({

  descText,
  gameLoge,
  borderRadius,
  className,
}) => {
  return (
    <div className={classnames(styles.bannerWrap, className)}>
      <div className="icon-banner">
        <img src={gameLoge} alt="" />
      </div>
      <div className="banner-content">
        <div className="banner-title">游戏<span className="title-green">全生命周期</span>分析 与智能运营平台</div>
        <div className="banner-dsc">{descText}</div>
        <div className="game-btn">
          <Button
            btnType="primary"
            href="/doGameGroup"
            target="_blank"
            style={{ borderRadius: borderRadius }}
          >
            体验游戏 Demo
          </Button>
          <div className="game-btn-title">更具<span>营销能力</span>的游戏用户运营解决方案</div>
        </div>
        <div className="banner-cross">
          <img
            className="icon-cross"
            src={require("assets/images/solution/game/icon-cross.png").default}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};
export default GameBanner;
