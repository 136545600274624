/*
 * @Author: liyu
 * @Date: 2022-04-18 16:37:17
 * @LastEditTime: 2022-04-22 14:04:41
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/GameFooter/index.tsx
 */
import React from "react";
import ScanCodeConsultButton from "components/ScanCodeConsultButton";
import * as styles from "./index.module.less";
import Button from "components/Base/Button";
import { isPc } from "utils/utils";

interface GameFooterProps {
    title?: string,
    gameDsc?: string,
  btnText?: string
  flag?:boolean
}
const renderBtn = (btnText) => {
  return ( <Button
    btnType="primary"
    href="/doGameGroup"
    target="_blank"
  >
    {btnText}
  </Button>)
}
const renderCodeBtn = (btnText) => {
  return (
    <ScanCodeConsultButton
    buttonText={btnText}
    qrcode={
      require(`assets/images/solution/game/mini-program-code.png`).default
    }
    alt={btnText}
      imgBottomText="微信扫码&nbsp;免费获取"
     
    />
  )
}
const GameFooter:React.FC<GameFooterProps> = ({title,gameDsc,btnText,flag}) => {
    return ( <div className={styles.immediateAppointment}>
        <div className="appointment-title">{title}</div>
    <div className="appointment-dsc">
      {gameDsc}
    </div>
    <div className="appointment-btn">
     {flag?renderCodeBtn(btnText):renderBtn(btnText)}
      <Button
        btnType="primary"
        className="sd-button-glass"
        href="https://www.sensorsdata.cn/form/parade.html"
        target="_blank"
      >
        预约演示
      </Button>
    </div>
  </div>)
}

export default GameFooter