/*
 * @Author: liyu
 * @Date: 2022-04-13 10:05:49
 * @LastEditTime: 2022-04-22 11:22:17
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/SectionProgram/index.tsx
 */
import React, { useRef } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import Button from 'components/Base/Button'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import { isBrowser, isPc } from 'utils/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/components/scrollbar/scrollbar.min.css'
import 'swiper/components/pagination/pagination.min.css'

SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])
interface SectionProgram {
  isFixed: string
  className: string
}
const swiperDataMb = [
  {
    imgUrl: require('assets/images/solution/game/game-swiper1.png').default,
    title: '游戏全生命周期分析',
    content: '调控游戏平衡，监控渠道质量，了解玩家需求',
  },
  {
    imgUrl: require('assets/images/solution/game/game-swiper2.png').default,
    title: '玩家趋势智能预测',
    content: '预测流失玩家，预估付费概率，从玩家短期付费表现预测长期 LTV 结果',
  },
  {
    imgUrl: require('assets/images/solution/game/game-swiper3.png').default,
    title: '玩家精细化运营触达',
    content: '基于玩家标签画像构建差异化运营计划，流程画布实现智能化触达',
  },
]
const swiperDataPc = [
  {
    imgUrl: require('assets/images/solution/game/game-swiper1.png').default,
  },
  {
    imgUrl: require('assets/images/solution/game/game-swiper2.png').default,
  },
  {
    imgUrl: require('assets/images/solution/game/game-swiper3.png').default,
  },
]
const renderProgressBar = () => (
  <div className={`progress-bar`}>
    <span className="cur-bar cur-bar2"></span>
  </div>
)

const renderSwiperPc = () => {
  return (
    <>
      {swiperDataPc.map((item, index) => {
        return (
          <SwiperSlide key={index}>
            <img src={item.imgUrl} />
          </SwiperSlide>
        )
      })}
    </>
  )
}
const renderSwiperMb = () => {
  return (
    <>
      {swiperDataMb.map((item) => {
        return (
          <SwiperSlide key={item.title}>
            <div>
              <img src={item.imgUrl} alt="" />
            </div>
            <div className="introduce-title-mb">{item.title}</div>
            <div className="section-content-mb">{item.content}</div>
          </SwiperSlide>
        )
      })}
    </>
  )
}
const SectionProgram = ({ isFixed, className }) => {
  const refBar = useRef<any>()

  const onSwiper = (swiper) => {
    if (isBrowser() && isPc() && swiper) {
      window.addEventListener('scroll', () => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 1300 && scrollTop < 2100) {
          swiper?.slideTo(1)
          refBar.current.className = 'cur-bar cur-bar1'
        } else if (scrollTop < 1300) {
          swiper?.slideTo(0)
          refBar.current.className = 'cur-bar'
        } else if (scrollTop > 2100) {
          swiper?.slideTo(2)
          refBar.current.className = 'cur-bar cur-bar2'
        }
      })
    }
  }

  return (
    <section className={classnames(styles.marketingCycle, className)}>
      {/* 左侧 */}
      <ul className="precept-dsc">
        <li>
          <div className="game-top"></div>
          <div className="precept-detail">
            <div className="game-introduce">
              <div className="introduce-title">游戏全生命周期分析</div>
              <ul>
                <li className="introduce-section">
                  <div className="section-title">产品研发测试调优</div>
                  <div className="section-content">
                    结合玩家数据与行为路径，保持游戏平衡，提升玩家游戏体验，优化游戏流失节点，提高玩家留存
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">优化买量投放策略</div>
                  <div className="section-content">
                    打通渠道投放数据与玩家留存、付费等关键行为指标，掌握渠道质量调整投放策略，实现投放降本增效
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">精细化运营洞察</div>
                  <div className="section-content">
                    交叉分析玩家需求，发现首充复购关键，提高付费规模，迭代运营活动设计，提高活动参与率
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div className="game-top"></div>
          <div className="precept-detail">
            <div className="game-introduce">
              <div className="introduce-title">玩家趋势智能预测</div>
              <ul>
                <li className="introduce-section">
                  <div className="section-title">玩家流失提前干预</div>
                  <div className="section-content">
                    基于玩家行为数据，结合 AI 算法预测高风险流失玩家群体，针对性采取挽留和改进措施
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">评估玩家付费意愿</div>
                  <div className="section-content">
                    预测玩家付费概率，定位转化关键行为，为游戏体验和活动优化提供参考
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">玩家 LTV 预测</div>
                  <div className="section-content">
                    从玩家短期付费表现预测长期 LTV 结果，从更高的视角衡量渠道价值，优化调整投放策略
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <div className="game-top"></div>
          <div className="precept-detail">
            <div className="game-introduce">
              <div className="introduce-title">玩家精细化运营触达</div>
              <ul>
                <li className="introduce-section">
                  <div className="section-title">玩家画像规划活动设计</div>
                  <div className="section-content">
                    基于玩家信息和行为数据，构建玩家画像，深入理解玩家需求与偏好，为活动设计规划提供支撑
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">玩家分层差异化运营</div>
                  <div className="section-content">
                    基于玩家属性、标签与行为特征，设置差异化运营计划，提升不同层级玩家的活动体验
                  </div>
                </li>
                <li className="introduce-section">
                  <div className="section-title">自动化触达提升人效</div>
                  <div className="section-content">规划玩家体验旅程，在不同行为节点智能化触达，提升玩家转化与留存</div>
                </li>
              </ul>
            </div>
            {/* {isBrowser() && !isFixed && window.scrollY > 670 ? renderProgressBar() : ''} */}
          </div>
        </li>
      </ul>
      {/* 右侧 */}
      <div className={`precept-swiper ${isBrowser() && !isFixed && window.scrollY > 670 ? 'pt-200' : ''}`}>
        <div className={`precept-content ${isPc() ? isFixed : ''}`}>
          <div className="game-top"></div>
          <div className="game-content">
            {/* 进度条 */}
            <div className={`progress-bar ${isFixed ? 'fixed-bar' : ''}`}>
              <span className="cur-bar" ref={(value) => (refBar.current = value)}></span>
            </div>
            <div className="precept-title ">
              更具<span className="emphasis">营销能力</span>
              的游戏用户运营解决方案
            </div>
            <div className="graphic-panel">
              <Swiper
                className="graphic-swiper graphic-swiper-pc  pc"
                direction={'vertical'} //切换方向
                autoplay={!isPc()} //自动播放
                onSwiper={onSwiper}
                allowTouchMove={false} //允许触摸滑动
                autoHeight={true} //自动高度
                // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
                effect={'fade'} //切换效果 淡入淡出
                // fadeEffect={{ crossFade: isPc() }}
                speed={800}
                // mousewheel={true}
              >
                {renderSwiperPc()}
              </Swiper>
              <Swiper
                className="graphic-swiper graphic-swiper-mb mobile"
                direction={'horizontal'} //切换方向
                autoplay={!isPc()} //自动播放
                allowTouchMove={true} //允许触摸滑动
                autoHeight={true} //自动高度
                // 'slide', 'fade', 'cube', 'coverflow' or 'flip'
                effect={'fade'} //切换效果 淡入淡出
                // fadeEffect={{ crossFade: isPc() }}
                speed={800}
                mousewheel={true}
                scrollbar={true}
              >
                {renderSwiperMb()}
              </Swiper>
            </div>

            {/* 按钮 */}
            {isPc() ? (
              <Button
                btnType="primary"
                href="/doGameGroup"
                target="_blank"
                style={
                  {
                    borderRadius: '20px',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(180px)',
                    zIndex: 1,
                  }
                  // : {
                  //     borderRadius: "20px",
                  //     margin: "2rem 0 4rem 0",
                  //   }
                }
              >
                体验游戏 Demo
              </Button>
            ) : (
              <ScanCodeConsultButton
                buttonText="扫码获取官方资料"
                qrcode={require(`assets/images/solution/game/mini-program-code.png`).default}
                alt="微扫码获取官方资料"
                imgBottomText="微信识别&nbsp;免费获取"
                style={{
                  margin: '2rem 0 4rem 0',
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionProgram
