/*
 * @Author: liyu
 * @Date: 2022-04-14 15:41:25
 * @LastEditTime: 2022-04-22 11:15:13
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/SectionResources/index.tsx
 */
import React from 'react'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import classnames from 'classnames'
import * as styles from './index.module.less'
import Button from 'components/Base/Button'
import { isPc } from 'utils/utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade } from 'swiper'
import 'swiper/swiper-bundle.css'
SwiperCore.use([Pagination, Scrollbar, A11y, Autoplay, Navigation, Virtual, EffectFade])
interface SectionProgram {
  isFixed: string
  className: string
}
const renderGameCardPc = () => (
  <ul className="information-type game-card-pc">
    <li className="information-detail">
      <div className="information-content">
        <span className="information-icon"></span>
        <span className="information-dsc">玩家留存提前洞察</span>
        <span className="information-message">应用 AI 算法实现玩家流失、转化、LTV 智能预测，调整策略先人一步</span>
      </div>
    </li>
    <li className="information-detail">
      <div className="information-content">
        <span className="information-icon information-icon-two"></span>
        <span className="information-dsc">玩家精细化运营触达</span>
        <span className="information-message">智能推荐、A/B 测试、智能运营，提供丰富的玩家精准运营触达手段</span>
      </div>
    </li>
    <li className="information-detail">
      <div className="information-content">
        <span className="information-icon information-icon-three"></span>
        <span className="information-dsc">长期陪伴式服务</span>
        <span className="information-message">关键项目陪跑帮助您完成落地，数据意识赋能帮助您更好地发挥数据价值</span>
      </div>
    </li>
  </ul>
)
const renderGameCardMb = () => {
  return (
    <Swiper
      className="game-card-swiper-mb information-type"
      autoplay={true}
      allowTouchMove={true}
      autoHeight={true}
      pagination={{ type: 'bullets' }}
      speed={2000}
    >
      <SwiperSlide>
        <div className="information-detail">
          <div className="information-content">
            <span className="information-icon"></span>
            <span className="information-dsc">玩家留存提前洞察</span>
            <span className="information-message">应用 AI 算法实现玩家流失、转化、LTV 智能预测，调整策略先人一步</span>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="information-detail">
          <div className="information-content">
            <span className="information-icon information-icon-two"></span>
            <span className="information-dsc">玩家精细化运营触达</span>
            <span className="information-message">智能推荐、A/B 测试、智能运营，提供丰富的玩家精准运营触达手段</span>
          </div>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="information-detail">
          <div className="information-content">
            <span className="information-icon information-icon-three"></span>
            <span className="information-dsc">长期陪伴式服务</span>
            <span className="information-message">
              关键项目陪跑帮助您完成落地，数据意识赋能帮助您更好地发挥数据价值
            </span>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}
const renderVideoPc = () => {
  return (
    <div className="information-video">
      <div className="video-player">
        <img src={require('assets/images/solution/game/game-video.png').default} alt="" />

        <Button btnType="text" href="https://school.sensorsdata.cn/liveVideo?liveId=3519" target="_blank">
          <span className="play-btn"></span>
        </Button>
      </div>
      <div className="video-content">
        <div className="video-title">研运一体化双重赋能，游戏行业超强升级攻略！</div>
        <div className="video-dsc">标签：数据分析方法、标签体系、精细化研运</div>
        <div className="video-massage">
          <div className="video-lightspot">内容亮点：</div>
          <div className="video-type">
            <ul>
              <li>一键掌握五类游戏分析方法</li>
              <li>深入讲解游戏精品化四个阶段</li>
              <li>游戏精品化、研运一体化业务全流程</li>
            </ul>
          </div>
        </div>
        <div className="video-free-btn">
          <Button
            btnType="primary"
            href="https://school.sensorsdata.cn/liveVideo?liveId=3519"
            target="_blank"
            style={{ borderRadius: '20px' }}
          >
            免费观看
          </Button>
        </div>
      </div>
    </div>
  )
}
const renderVideoMb = () => {
  return (
    <div className="more-dry block lg:hidden">
      <div className="dry-title">更多干货</div>
      <div className="dry-dsc">研运一体化双重赋能，游戏行业超强升级攻略!</div>
      <div className="dry-video">
        <Button btnType="text" href="https://school.sensorsdata.cn/liveVideo?liveId=3519" target="_blank">
          <img src={require('assets/images/solution/game/wecom-temp-mb.png').default} alt="" />
        </Button>
      </div>
      <ul className="dry-card">
        <li>
          <Button
            btnType="text"
            href="https://www.sensorsdata.cn/school/library/5579b3528dfdc038e1984231ebe94a05"
            target="_blank"
          >
            <div className="dry-type">
              <img src={require('assets/images/solution/game/icon-dry-1.png').default} alt="" />
              <span>2021 中国游戏市场挑战与机遇盘点</span>
              <strong>免费下载</strong>
            </div>
          </Button>
        </li>
        <li>
          <Button
            btnType="text"
            href="https://www.sensorsdata.cn/school/library/1d671983cc6c99b95370b5d009ce87f3"
            target="_blank"
          >
            <div className="dry-type">
              <img src={require('assets/images/solution/game/icon-dry-2.png').default} alt="" />
              <span>数据驱动游戏产品精品化运营精细化</span>
              <strong>免费下载</strong>
            </div>
          </Button>
        </li>
      </ul>
    </div>
  )
}
const SectionResources = ({ className }) => {
  return (
    <section className={classnames(styles.packageInformation, className)}>
      {/* title */}
      <div className="information-title">
        三大核心优势助力游戏<span className="title-green">研运</span>升级
      </div>
      {/* card */}
      {renderGameCardPc()}
      {renderGameCardMb()}
      {/* {isPc() ? renderGameCardPc() : renderGameCardMb()} */}
      {/* btn */}
      <div className="information-btn">
        <ScanCodeConsultButton
          buttonText="扫码获取方案资料"
          qrcode={require(`assets/images/solution/game/mini-program-code.png`).default}
          alt="扫码获取方案资料"
        />
      </div>
      {/* video */}
      {/* {isPc() ? renderVideoPc() : renderVideoMb()} */}
      {renderVideoPc()}
      {renderVideoMb()}
    </section>
  )
}
export default SectionResources
