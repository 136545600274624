/*
 * @Author: liyu
 * @Date: 2022-04-15 11:23:13
 * @LastEditTime: 2022-08-17 15:42:06
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/SectionReservation/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import GameFooter from '../GameFooter'
import { isPc } from 'utils/utils'
const SectionReservation = ({ className }) => {
  return (
    <section className={classnames(styles.reservationConsultation, className)}>
      <div className="customer-voice">
        <div className="customer-detail">
          <div className="customer-evaluate">
            <div className="customer-icon">
              <img src={require('assets/images/solution/game/customer-voice.png').default} alt="" />
              <span>来自客户的声音</span>
            </div>
            <div className="customer-dsc">
              <div>
                “
                游戏行业是一个追求新与快的典型行业，只有不断地快速推陈出新才能让玩家保持持续的新鲜感，而与神策数据强强合作，无疑是加强了我们沿着正确的轨道不断创新、突破的能力。目前，神策数据让奥拉手游项目更好地从用户行为中理解用户、挖掘用户需求，使我们能快速迭代产品，以满足玩家心理，延长游戏的生命力。未来，与神策数据携手，相信还会做出更多有价值的探索。”
              </div>
              <div>奥拉手游项目负责人</div>
            </div>
          </div>
          {/* <div className="customer-card">
            <img
              src={
                isPc()
                  ? require('assets/images/solution/game/customer-icon-1.png').default
                  : require('assets/images/solution/game/customer-icon-1-mb.png').default
              }
              alt=""
            />
            <img
              src={
                isPc()
                  ? require('assets/images/solution/game/customer-icon-2.png').default
                  : require('assets/images/solution/game/customer-icon-2-mb.png').default
              }
              alt=""
            />
            <img
              src={
                isPc()
                  ? require('assets/images/solution/game/customer-icon-3.png').default
                  : require('assets/images/solution/game/customer-icon-3-mb.png').default
              }
              alt=""
            />
            <img
              src={
                isPc()
                  ? require('assets/images/solution/game/customer-icon-5.png').default
                  : require('assets/images/solution/game/customer-icon-5-mb.png').default
              }
              alt=""
            />
          </div> */}
        </div>
      </div>
      <GameFooter
        title={'抢滩游戏精品化时代'}
        gameDsc={'立即预约，与神策咨询专家携手探索游戏研运升级路径'}
        btnText={'体验游戏 Demo'}
      />
    </section>
  )
}

export default SectionReservation
