/*
 * @Author: liyu
 * @Date: 2022-04-15 10:18:57
 * @LastEditTime: 2022-04-15 16:55:38
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Game/SectionCounsel/index.tsx
 */
import React from "react";
import classnames from "classnames";
import * as styles from "./index.module.less";
import Button from "components/Base/Button";

const SectionCounsel = ({ className }) => {
  return (
    <section className={classnames(styles.otherConsulting, className)}>
      <div className="consulting-title">更多干货</div>
      <div className="consulting-card">
        <div className="card-content">
          <header>
            <img
              src={
                require("assets/images/solution/game/consulting-card-1.png")
                  .default
              }
              alt=""
            />
          </header>
          <main className="card-container">
            <div className="card-tag">研究报告</div>
            <div className="card-title">2021 中国游戏市场挑战与机遇盘点</div>
            <div className="card-dsc">
              破局之道：数据驱动实现产品精品化、运营精细化。
            </div>
            <Button
              btnType="text"
              href="https://www.sensorsdata.cn/school/library/5579b3528dfdc038e1984231ebe94a05"
              target="_blank"
            >
              <div className="card-download">免费下载</div>
            </Button>
          </main>
        </div>
        <div className="card-content">
          <header>
            <img
              src={
                require("assets/images/solution/game/consulting-card-2.png")
                  .default
              }
              alt=""
            />
          </header>
          <main className="card-container">
            <div className="card-tag">白皮书</div>
            <div className="card-title">数据驱动游戏产品精品化运营精细化</div>
            <div className="card-dsc">
              全面梳理游戏厂商基于数据驱动实现产品精品化、运营精细化的方法论，赋能游戏设计与玩家体验全流程，持续延长游戏…
            </div>
            <Button
              btnType="text"
              href="https://www.sensorsdata.cn/school/library/1d671983cc6c99b95370b5d009ce87f3"
              target="_blank"
            >
              <div className="card-download">免费下载</div>
            </Button>
          </main>
        </div>
      </div>
    </section>
  );
};

export default SectionCounsel;
